import React from "react";
import {
  ChevronCompactLeft,
  ChevronCompactRight,
  Copy,
  EyeFill,
  ImageFill,
  Pencil,
  PlusCircle,
  Trash,
} from "react-bootstrap-icons";
import {
  Column,
  ConnectionData,
  DataItem,
  DataTableProps,
} from "../Interface/types";

const Table: React.FC<DataTableProps> = ({
  columns,
  data,
  itemsPerPageOptions,
  itemsPerPage,
  onItemsPerPageChange,
  currentPage,
  total,
  onPageChange,
  name,
  addIcon,
  deleteIcon,
  handleClick,
  handleEditClick,
  handleSearch,
  searchValue,
  handleSort,
  sortDirection,
  sortField,
  setIsDeleteModalVisible,
  setDeleteId,
  editIcon,
  title,
  handleViewClick,
  viewStrategy,
  copyToClipboard,
  setSelectedUserStatus,
  handleDeleteClick,
}) => {
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.onerror = null; // Prevent infinite loop
    event.currentTarget.src = "market.png"; // Set the fallback image
  };
  const totalPages = Math.ceil(total / itemsPerPage);
  const getValue = (item: DataItem, accessor: string) => {
    return accessor
      .split(".")
      .reduce((acc: any, part) => acc && acc[part], item);
  };
  const maxVisiblePages = 3;

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const onPagenumberClick = (page: number) => {
    const targetElement = document.querySelector(".app-card-body");
    if (targetElement) {
      targetElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    onPageChange(page)
  }
  return (
    <div className="app-main-content d-flex flex-column h-100">
      <div className="app-content-card d-flex flex-column h-100">
        <div className="app-content-header d-md-flex justify-content-md-between">
          <h3 className="mb-0">{name}</h3>
          <div className="app-content-filter w-100 justify-content-md-end">
            <div className="d-flex align-items-center justify-content-end col-lg-6">
              <div className="search-box me-3">
                <div
                  className="input-group search-bar"
                  style={{ minWidth: "263px" }}
                >
                  <button
                    className="input-group-text icon btn dropdown"
                    id="basic-addon1"
                    aria-expanded="false"
                  >
                    <i className="i-search icon-md d-block"></i>
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchValue}
                    onChange={(e) => handleSearch?.(e.target.value)}
                  />
                </div>
              </div>

              {addIcon && (
                <div className="btn-bar ">
                  <button
                    className="btn btn-primary me-auto d-flex"
                    data-bs-placement="top"
                    onClick={handleClick}
                  >
                    <PlusCircle />
                    <span className="btn-title ms-2">{title}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="app-card-body">
          <table className="table qt-table">
            <thead className="table-light">
              <tr>
                {columns.map((column: Column) => (
                  <th
                    key={column.id}
                    colSpan={column.colSpan || 2}
                    scope={column.scope || ""}
                    onClick={() =>
                      column.accessor !== "image_url" &&
                      column.accessor !== "action" &&
                      handleSort?.(column.accessor)
                    }
                    className="table-head"
                  >
                    {column.label}
                    {column.accessor !== "image_url" &&
                    column.accessor !== "action" ? (
                      sortField === column.accessor ? (
                        sortDirection === "asc" ? (
                          <i className="i-arrow-up-icon" />
                        ) : (
                          <i className="i-arrow-down-icon" />
                        )
                      ) : (
                        <i className="arrow-icons" />
                      )
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((item: any, index) => (
                  <tr
                    key={index}
                    className={item?.is_deleted ? "row-disabled" : undefined}
                  >
                    {columns.map((column: Column) => {
                      const value = getValue(item, column.accessor);
                      if (column.accessor === "image_url") {
                        return (
                          <td
                            key={column.id}
                            colSpan={column.colSpan || 2}
                            style={{ textAlign: "center" }}
                          >
                            {value ? (
                              <img
                                src={`${process.env.REACT_APP_API_URL}${value}?${new Date().getTime()}`}
                                alt="asset"
                                style={{
                                  height: "36px",
                                  objectFit: "cover",
                                }}
                                onError={handleImageError}
                              />
                            ) : (
                              <ImageFill size={25} title="No Image" />
                            )}
                          </td>
                        );
                      }
                      if (column.accessor === "risk_profile") {
                        return (
                          <td
                            key={column.id}
                            colSpan={column.colSpan || 2}
                            title={value}
                            className="data-cell"
                          >
                            {value.split(",").map((risk: string) => (
                              <i
                                key={risk}
                                className={`icon-${risk.trim()}-risk-small d-block`}
                              />
                            ))}
                          </td>
                        );
                      }
                      if (column.accessor === "is_inactive") {
                        return (
                          <td
                            key={column.id}
                            colSpan={column.colSpan || 2}
                            title={value ? "Inactive" : "Active"}
                            className="data-cell"
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={!value}
                                readOnly
                                onClick={() => {
                                  setSelectedUserStatus(value);
                                  handleDeleteClick?.();
                                }}
                                disabled={item?.is_deleted}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        );
                      }
                      if (column.accessor === "connections") {
                        return (
                          <td
                            key={column.id}
                            colSpan={column.colSpan || 2}
                            className="data-cell"
                          >
                            {value &&
                              value.map((data: ConnectionData) => {
                                let img_src = "";
                                switch (data.identifier) {
                                  case 1:
                                    img_src = "/alpaca.png";
                                    break;
                                  case 2:
                                    img_src = "/alpaca-live.png";
                                    break;
                                  case 3:
                                    img_src = "/dhan.png";
                                    break;
                                  default:
                                    img_src = "";
                                    break;
                                }
                                if (img_src === "") return;
                                return (
                                  <img
                                    src={img_src}
                                    alt={data.name}
                                    key={data.name}
                                    title={data.name}
                                    style={{
                                      height: "30px",
                                      objectFit: "cover",
                                    }}
                                    className="me-3"
                                  />
                                );
                              })}
                          </td>
                        );
                      }
                      if (column.accessor === "action") {
                        if (viewStrategy || editIcon || deleteIcon) {
                          return (
                            <td
                              key={column.id}
                              colSpan={column.colSpan || 2}
                              className="data-cell"
                            >
                              <div className="action-bar">
                                {viewStrategy && (
                                  <>
                                    <button
                                      className="btn btn-info"
                                      title="Copy"
                                      onClick={() =>
                                        copyToClipboard?.(item?.id)
                                      }
                                    >
                                      <Copy />
                                    </button>
                                    <button
                                      className="btn btn-success"
                                      title="View"
                                      onClick={() => {
                                        handleViewClick?.(item?.id);
                                      }}
                                    >
                                      <EyeFill />
                                    </button>
                                  </>
                                )}
                                {editIcon && (
                                  <button
                                    className="btn btn-primary"
                                    title="Edit"
                                    onClick={() => handleEditClick?.(item.id)}
                                  >
                                    <Pencil />
                                  </button>
                                )}
                                {deleteIcon && (
                                  <button
                                    className="btn bg-danger"
                                    title="Delete"
                                    onClick={() => {
                                      setDeleteId?.(item.id);
                                      setIsDeleteModalVisible?.(true);
                                    }}
                                  >
                                    <Trash />
                                  </button>
                                )}
                              </div>
                            </td>
                          );
                        }
                      }
                      return (
                        <td
                          key={column.id}
                          colSpan={column.colSpan || 1}
                          title={value}
                          className="data-cell"
                        >
                          {value}
                        </td>
                      );
                    })}
                  </tr>
                ))
              ) : (
                <tr style={{ border: "none" }}>
                  <td
                    colSpan={columns.length + 1}
                    className="no-record-container"
                  >
                    <div className="no-record-found">No records found</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {total > 0 && (
          <div className="app-card-footer">
            <p className="mb-0">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, total)} of {total} records
            </p>
            <div className="d-flex">
              <div className="page-item-box d-flex align-items-center">
                <div className="dropdown table-entries me-3">
                  <button
                    className="footer-btn-paggy btn-secondary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {itemsPerPage}
                  </button>
                  <ul className="dropdown-menu">
                    {itemsPerPageOptions.map((option) => (
                      <li key={option}>
                        <button
                          className="dropdown-item"
                          onClick={() => onItemsPerPageChange(option)}
                        >
                          {option}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <p className="mb-0">records per page</p>
              </div>
              <nav aria-label="Page navigation">
                <ul className="pagination mb-0">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => onPagenumberClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <ChevronCompactLeft size={20} title="Previous" />
                    </button>
                  </li>
                  {startPage > 1 && (
                    <>
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={() => onPagenumberClick(1)}
                        >
                          1
                        </button>
                      </li>
                      {startPage > 2 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                    </>
                  )}
                  {pages.map((page) => (
                    <li
                      key={page}
                      className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => onPagenumberClick(page)}
                      >
                        {page}
                      </button>
                    </li>
                  ))}
                  {endPage < totalPages && (
                    <>
                      {endPage < totalPages - 1 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={() => onPagenumberClick(totalPages)}
                        >
                          {totalPages}
                        </button>
                      </li>
                    </>
                  )}
                  <li
                    className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => onPageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <ChevronCompactRight size={20} title="Next" />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
