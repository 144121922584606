import React, { useState } from "react";
import { EyeFill, EyeSlashFill, Floppy } from "react-bootstrap-icons";
import Select from "react-select";
import { Dropdown, InputField } from "../common/InputField/inputField";
import {
  AssetData,
  FormProps,
  MarketData,
  validateStrategyForm,
} from "../common/Interface/types";
export const Form: React.FC<FormProps> = ({
  formData,
  options,
  selectedMarket,
  marketData,
  errors,
  handleChange,
  handleMarketSelect,
  handleSubmit,
  filteredAssets = [],
  selectedAsset = null,
  handleAssetSelect = () => {},
  handleSelectChange,
  handleRiskProfileSelect = () => {},
  setShowErrors,
  setErrors,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const validationErrors = validateStrategyForm(formData, currentStep);
  const handleBack = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };
  const handleNext = (e: any) => {
    e.preventDefault();
    if (currentStep < 2) {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setShowErrors(true);
        return;
      }
    }
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };
  const handleStepClick = (step: number) => {
    if (step < currentStep) {
      setCurrentStep(step);
    } else if (step === currentStep) {
      return;
    } else {
      if (currentStep < 2) {
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          setShowErrors(true);
          return;
        }
      }
      setCurrentStep(step);
    }
  };
  const getMarketLabel = (market: MarketData) => market.name;
  const getAssetLabel = (asset: AssetData) => asset.name;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const PasswordIcon = (show: boolean) => {
    return show ? (
      <EyeFill style={{ fontSize: "24px" }} />
    ) : (
      <EyeSlashFill style={{ fontSize: "24px" }} />
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="step-indicator">
        <div
          className={`step ${currentStep === 1 ? "active" : ""}`}
          onClick={() => handleStepClick(1)}
        >
          Step 1
        </div>
        <div className={`line ${currentStep === 1 ? "active" : ""}`}></div>
        <div
          className={`step ${currentStep === 2 ? "active" : ""}`}
          onClick={() => handleStepClick(2)}
        >
          Step 2
        </div>
        <div className={`line ${currentStep === 2 ? "active" : ""}`}></div>
        <div
          className={`step ${currentStep === 3 ? "active" : ""}`}
          onClick={() => handleStepClick(3)}
        >
          Step 3
        </div>
      </div>
      <div className="add-market-content">
        <div className="my-3 my-md-4">
          <div className="add-market-card strategy-section">
            <div className="row g-3">
              {currentStep === 1 && (
                <>
                  <div className="col-lg-6">
                    <Dropdown
                      data={marketData}
                      label="Market"
                      selectedItem={selectedMarket}
                      handleSelect={handleMarketSelect}
                      getItemLabel={getMarketLabel}
                      error={errors.market_name}
                      placeholder="Select a Market"
                      message="No market available"
                    />
                  </div>
                  <div className="col-lg-6">
                    <Dropdown
                      label="Asset"
                      data={filteredAssets}
                      selectedItem={selectedAsset}
                      handleSelect={handleAssetSelect}
                      getItemLabel={getAssetLabel}
                      error={errors.asset_name}
                      placeholder="Select an Asset"
                      message="No assets available"
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      label="Strategy Name"
                      error={errors.name}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      id="password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      label="Password"
                      error={errors.password}
                      icon={
                        <i
                          style={{
                            margin: "auto -35px",
                            cursor: "pointer",
                            color: "white",
                          }}
                          onClick={onPasswordClick}
                        >
                          {PasswordIcon(showPassword)}
                        </i>
                      }
                    />
                  </div>
                  <div className="col-lg-6 qt-input">
                    <label htmlFor="inputState" className="form-label">
                      Recommended Stoploss (%)
                    </label>
                    <Select
                      options={options?.stopLoss}
                      onChange={handleSelectChange?.("recommended_stoploss")}
                      placeholder="Select Stoploss"
                      value={options?.stopLoss?.find(
                        (option) =>
                          option.value === formData.recommended_stoploss,
                      )}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      id="initial_balance"
                      name="initial_balance"
                      value={formData.initial_balance}
                      pattern="^[0-9]*$"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        e.target.value = value.slice(0, 8);
                        handleChange?.(e);
                      }}
                      onChange={handleChange}
                      label={`Initial balance ${selectedMarket ? `(${selectedMarket?.currency_symbol})` : ""}`}
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="qt-input">
                      <label htmlFor="inputEmail4" className="form-label w-100">
                        Inverse
                      </label>
                      <div className="form-check form-check-inline">
                        <InputField
                          id="inverseTrue"
                          name="inverse"
                          value="true"
                          onChange={handleChange}
                          type="radio"
                          radio={true}
                          label="True"
                          checked={formData.inverse}
                        />
                      </div>
                      <div className="form-check form-check-inline">
                        <InputField
                          id="inverseFalse"
                          name="inverse"
                          value="false"
                          onChange={handleChange}
                          type="radio"
                          radio={true}
                          label="False"
                          checked={!formData.inverse}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="qt-input">
                      <label htmlFor="inputEmail4" className="form-label w-100">
                        Pyramid
                      </label>
                      <div className="form-check form-check-inline">
                        <InputField
                          id="pyramidTrue"
                          name="pyramid"
                          value="true"
                          onChange={handleChange}
                          type="radio"
                          radio={true}
                          label="True"
                          checked={formData.pyramid}
                        />
                      </div>
                      <div className="form-check form-check-inline">
                        <InputField
                          id="pyramidFalse"
                          name="pyramid"
                          value="false"
                          onChange={handleChange}
                          type="radio"
                          radio={true}
                          label="False"
                          checked={!formData.pyramid}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {currentStep === 2 && (
                <>
                  <div className="col-lg-12 qt-input">
                    <InputField
                      id="chart_url"
                      name="chart_url"
                      type="text"
                      value={formData.chart_url}
                      onChange={handleChange}
                      label="Chart URL"
                    />
                  </div>
                  <div className="col-lg-12 qt-input">
                    <InputField
                      id="csv_url"
                      name="csv_url"
                      type="text"
                      value={formData.csv_url}
                      onChange={handleChange}
                      label="CSV URL"
                    />
                  </div>
                  <div className="col-lg-12 qt-input">
                    <InputField
                      id="video_url"
                      name="video_url"
                      type="text"
                      value={formData.video_url}
                      onChange={handleChange}
                      label="Video URL"
                    />
                  </div>
                </>
              )}
              {currentStep === 3 && (
                <>
                  <div className="col-lg-12">
                    <div className="qt-input">
                      <label htmlFor="inputEmail4" className="form-label w-100">
                        Risk Profile
                      </label>
                      <div
                        className="btn-group qt-btn-group w-100"
                        role="group"
                        aria-label="Risk profile selector"
                      >
                        <button
                          type="button"
                          className={`btn btn-outline-primary ${formData.risk_profile === "low" ? "active" : ""}`}
                          onClick={() => handleRiskProfileSelect("low")}
                        >
                          <i className={`icon-low-risk d-block`}></i>
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-primary ${formData.risk_profile === "medium" ? "active" : ""}`}
                          onClick={() => handleRiskProfileSelect("medium")}
                        >
                          <i className={`icon-medium-risk d-block`}></i>
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-primary ${formData.risk_profile === "high" ? "active" : ""}`}
                          onClick={() => handleRiskProfileSelect("high")}
                        >
                          <i className={`icon-high-risk d-block`}></i>
                        </button>
                        <button
                          type="button"
                          className={`btn btn-outline-primary ${formData.risk_profile === "very-high" ? "active" : ""}`}
                          onClick={() => handleRiskProfileSelect("very-high")}
                        >
                          <i className={`icon-very-high-risk d-block`}></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 qt-input">
                    <label htmlFor="inputState" className="form-label">
                      Max Drawdown (%)
                    </label>
                    <Select
                      options={options?.maxDrawdown}
                      onChange={handleSelectChange?.("max_drawdown")}
                      placeholder="Select Drawdown"
                      value={options?.maxDrawdown?.find(
                        (option) => option.value === formData.max_drawdown,
                      )}
                    />
                    {errors?.max_drawdown && (
                      <div className="invalid-feedback d-block">
                        {errors.max_drawdown}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 qt-input">
                    <InputField
                      id="win_rate"
                      name="win_rate"
                      value={formData.win_rate}
                      onChange={handleChange}
                      label="Win Rate (%)"
                      error={errors?.win_rate}
                      pattern="^[0-9]*$"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        if (value && parseInt(value) > 100) {
                          value = "100";
                        }
                        if (value === "" || parseInt(value) < 1) {
                          value = "";
                        }
                        e.target.value = value;
                        handleChange?.(e);
                      }}
                    />
                  </div>

                  <div className="col-lg-6 qt-input">
                    <label htmlFor="inputState" className="form-label">
                      Time Frame
                    </label>
                    <Select
                      options={options?.timeFrame}
                      onChange={handleSelectChange?.("time_frame")}
                      placeholder="Select Time"
                      value={options?.timeFrame?.find(
                        (option) => option.value === formData.time_frame,
                      )}
                    />
                  </div>
                  <div className="col-lg-6 qt-input">
                    <InputField
                      id="profit_factor"
                      name="profit_factor"
                      value={formData.profit_factor}
                      onChange={handleChange}
                      label="Profit Factor (%)"
                      error={errors?.profit_factor}
                      pattern="^[0-9]*$"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        if (value && parseInt(value) > 100) {
                          value = "100";
                        }
                        if (value === "" || parseInt(value) < 1) {
                          value = "";
                        }
                        e.target.value = value;
                        handleChange?.(e);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="btn-bar d-flex">
          {currentStep > 1 && (
            <button
              type="button"
              className="btn btn-warning me-2"
              onClick={handleBack}
            >
              Back
            </button>
          )}
          {currentStep < 3 ? (
            <button
              type="reset"
              className="btn btn-info me-auto"
              onClick={handleNext}
            >
              Next
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary me-auto d-flex"
              data-bs-dismiss="modal"
            >
              <Floppy className="me-2" />
              Save
            </button>
          )}
        </div>
      </div>
    </form>
  );
};
