import React from "react";
import { Check2, Trash, XLg } from "react-bootstrap-icons";

interface ModalProps {
  title: string;
  onClose: () => void;
  onYesClose?: () => void;
  handleDeleteClick?: (id: any) => void;
  children?: React.ReactNode;
  isConfirmation?: boolean;
  isForUser?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  onClose,
  children,
  isConfirmation,
  handleDeleteClick,
}) => {
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div
        className="modal fade add-market-popup show d-block"
        tabIndex={-1}
        aria-labelledby="modal"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content border-primary">
            <div className="modal-header bg-primary">
              <h5 className="modal-title" id="modal">
                {title}
              </h5>
              {!isConfirmation && (
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={onClose}
                />
              )}
            </div>
            {isConfirmation ? (
              <div className="modal-body">
                <h4>{children}</h4>
              </div>
            ) : (
              <>{children}</>
            )}
            {isConfirmation && (
              <div className="modal-footer">
                <div className="btn-bar d-flex gap-2">
                  <button
                    type="button"
                    className="btn bg-danger"
                    style={{ color: "#ffffff" }}
                    onClick={handleDeleteClick}
                  >
                    <Trash className="me-2" /> Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-gray"
                    onClick={onClose}
                  >
                    <XLg className="me-2" /> Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const DiscardModal: React.FC<ModalProps> = ({
  title,
  onClose,
  onYesClose,
  children,
  isForUser,
}) => {
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div
        className="modal fade add-market-popup show d-block add-market-discard-popup"
        tabIndex={-1}
        aria-labelledby="modal"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modal">
                {title}
              </h5>
            </div>
            <div className="modal-body">
              <h4>{children}</h4>
            </div>
            <div className="modal-footer">
              <div className="btn-bar d-flex gap-2">
                <button
                  type="button"
                  className="btn bg-danger me-auto d-flex"
                  style={{ color: "#ffffff" }}
                  onClick={onYesClose}
                >
                  <Check2 className="me-2" />
                  {isForUser ? "Confirm" : "Yes"}
                </button>
                <button
                  type="button"
                  className="btn btn-gray"
                  onClick={onClose}
                >
                  <XLg className="me-2" /> {isForUser ? "Cancel" : "No"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
