import React from "react";
import logoImg from "../../../assets/img/logo.svg";

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-dark flex-md-nowrap">
        <div className="navbar-brand col-sm-3 col-md-2 mr-0 p-0">
          <span className="me-4">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </span>
          <img src={logoImg} alt="logo" />
        </div>
      </nav>
    </header>
  );
};
export default React.memo(Header);
