import React, { useContext } from "react";
import Dashboard from "./Dashboard";
import SignIn from "./SignIn";
import { AppContext } from "../App";
import "../assets/css/style.css";

const Home = () => {
  const { token } = useContext(AppContext);
  return <>{token ? <Dashboard /> : <SignIn />}</>;
};
export default React.memo(Home);
