import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Header, Sidebar } from "../common";
import { AssetColumn } from "../common/DataTable/column";
import Table from "../common/DataTable/table";
import { convertImageUrlToBase64 } from "../common/Dropzone/dropzone";
import { itemsPerPageOptions } from "../common/InputField/inputField";
import {
  AssetData,
  AssetsResponse,
  MarketData,
  MarketsResponse,
  validateAssetForm,
  ValidationErrors,
} from "../common/Interface/types";
import { usePaginationReducer } from "../common/useReducer/usePaginationReducer";
import { getData, postData, putData } from "../utils/api";
import { Form } from "./form";
import { DiscardModal, Modal } from "../common/Modal";

const AssetList = () => {
  const { token, setAdmin, setToastMessage } = useContext(AppContext);
  const [marketData, setMarketData] = useState<MarketData[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<MarketData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<AssetData>({
    market_id: "",
    name: "",
    ticker_name: "",
    image: "",
    symbol_token: "",
  });
  const [fileNames, setFileNames] = useState<string | null>(null);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const {
    state,
    setData,
    setPage,
    setItemsPerPage,
    setSort,
    setTotal,
    setTotalPages,
  } = usePaginationReducer();
  const [currentAsset, setCurrentAsset] = useState<AssetData | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (!localStorage.getItem("authToken")) setAdmin(null);
  }, [setAdmin]);

  const resetForm = () => {
    setFormData({
      name: "",
      market_id: "",
      ticker_name: "",
      image: "",
      symbol_token: "",
    });
    setSelectedMarket(null);
    setFileNames(null);
    setErrors({});
    setShowErrors(false);
    setIsModalVisible(false);
    setCurrentAsset(null);
  };
  const fetchAssets = async () => {
    setIsLoading(true);
    try {
      const query = new URLSearchParams({
        page: state.currentPage.toString(),
        limit: state.itemsPerPage.toString(),
        ...(searchValue && { search: searchValue.trim() }),
        ...(state.sortField && {
          sortColumn: state.sortField,
          sortOrder: state.sortDirection,
        }),
      }).toString();
      const response: AssetsResponse = await getData(
        `/admin/assets?${query}`,
        token,
      );
      const { data: assets, pageInfo } = response.assets;
      assets.forEach(asset => {
        // Assuming each asset has a 'Price' field and you want to add a '$' symbol
        if (asset.price) {
          const currencyCode = asset.market?.currency_code === "USDT" ? "USD" : asset.market?.currency_code
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,  // Ensures two decimal places
            maximumFractionDigits: 2   // Ensures two decimal places
          });
          asset.formatted_price = formatter.format(asset.price);
        }
      });
      setData(assets || []);
      setTotal(pageInfo.total);
      setTotalPages(Math.ceil(pageInfo.total / state.itemsPerPage));
    } catch (error: any) {
      setToastMessage({
        status: error.status,
        text: error?.data || error?.message,
        type: "error",
      });
    }
    setIsLoading(false);
  };
  const fetchMarkets = async () => {
    if (token) {
      try {
        const response: MarketsResponse = await getData(
          "/admin/markets",
          token,
        );
        setMarketData(response.markets.data || []);
      } catch (error: any) {
        setToastMessage({
          status: error.status,
          text: error?.data || error?.message,
          type: "error",
        });
      }
    }
  };
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= state.totalPages) {
      setPage(page);
    }
  };

  const handleItemsPerPageChange = (limit: number) => {
    setItemsPerPage(limit);
    setPage(1);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setIsDirty(true);
    showErrors && setErrors({});
  };
  const handleEditClick = async (id: number) => {
    const assetObj = state?.data?.find(
      (data: { id: number }) => data.id === id,
    );
    if (assetObj) {
      setCurrentAsset(assetObj);
      const imageBase64 = assetObj.image_url
      setFormData({
        market_id: assetObj?.market_id,
        name: assetObj?.name,
        ticker_name: assetObj?.ticker_name,
        image: imageBase64,
        symbol_token: assetObj?.symbol_token,
      });
      setFileNames(imageBase64);
      setSelectedMarket(
        marketData?.find((market) => market.id === assetObj?.market_id) || null,
      );
      setIsModalVisible(true);
    }
  };
  const handleMarketSelect = (market: MarketData) => {
    setIsDirty(true);
    setSelectedMarket(market);
    setFormData((prev: any) => ({ ...prev, market_id: market.id }));
  };
  const handleSortModelChange = (newModel: any) => {
    const currentSortField = state.sortField;
    const currentSortDirection = state.sortDirection;
    if (currentSortField === newModel) {
      if (currentSortDirection === "desc") {
        setSort(null, null);
      } else {
        setSort(newModel, "desc");
      }
    } else {
      setSort(newModel, "asc");
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateAssetForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    setIsDirty(false);
    try {
      if (currentAsset) {
        await putData(`/admin/assets/${currentAsset.id}`, token, formData);
        setToastMessage({
          text: "Asset updated succesfully",
          type: "success",
        });
      } else {
        await postData(`/admin/assets`, token, formData);
        setToastMessage({
          text: "Asset added succesfully",
          type: "success",
        });
      }
      fetchAssets();
      resetForm();
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };
  const handleClose = () => {
    if (isDirty) {
      setOpen(true);
    } else {
      resetForm();
    }
  };
  const handleNoClick = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    setOpen(false);
    setIsModalVisible(false);
    setIsDirty(false);
    resetForm();
  };

  useEffect(() => {
    fetchAssets();
    if (!searchValue && !state.sortField) {
      fetchMarkets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentPage,
    state.itemsPerPage,
    searchValue,
    state.sortField,
    state.sortDirection,
  ]);

  return (
    <>
      <Header />
      <main className="main">
        <Sidebar />
        <div className="app-main">
          <Table
            columns={AssetColumn}
            isLoading={isLoading}
            searchValue={searchValue}
            handleSearch={handleSearch}
            data={state.data}
            itemsPerPageOptions={itemsPerPageOptions}
            itemsPerPage={state.itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            currentPage={state.currentPage}
            total={state.total}
            onPageChange={handlePageChange}
            name="Assets"
            addIcon={true}
            deleteIcon={false}
            handleClick={() => setIsModalVisible(true)}
            handleEditClick={handleEditClick}
            handleSort={handleSortModelChange}
            sortField={state.sortField}
            sortDirection={state.sortDirection}
            editIcon={true}
            title="Add Asset"
          />
        </div>
      </main>
      {isModalVisible && (
        <Modal
          title={currentAsset ? "Update Asset" : "Add Asset"}
          onClose={handleClose}
        >
          <Form
            formData={formData}
            selectedMarket={selectedMarket}
            marketData={marketData}
            errors={errors}
            handleChange={handleChange}
            handleMarketSelect={handleMarketSelect}
            setFileNames={setFileNames}
            fileNames={fileNames}
            handleSubmit={handleSubmit}
            setFormData={setFormData}
            setIsDirty={setIsDirty}
          />
        </Modal>
      )}
      {open && (
        <DiscardModal
          title="Discard Changes"
          onClose={handleNoClick}
          onYesClose={handleYesClick}
          children="would like to discard changes made?"
        />
      )}
    </>
  );
};
export default React.memo(AssetList);
