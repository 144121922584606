import React from "react";
import { Floppy } from "react-bootstrap-icons";
import Select from "react-select";
import { MarketData, ValidationErrors } from "../common/Interface/types";

export interface MarketProps {
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  formData?: MarketData;
  dismissValue?: string;
  error?: ValidationErrors;
  currencyData?: {
    value: string;
    label: string;
    symbol: string;
  }[];
  handleCurrencySelect?: any;
  getCurrencyLabel?: any;
  timezone?: {
    value: number;
    label: string;
  }[];
  options?: {
    timezone: {
      value: number;
      label: string;
    }[];
    name: {
      value?: number;
      label: string;
    }[];
  };
  handleSelectChange?: any;
}

export const Form: React.FC<MarketProps> = ({
  handleSubmit,
  formData,
  options,
  dismissValue,
  error,
  currencyData,
  handleCurrencySelect,
  getCurrencyLabel,
  handleSelectChange,
}) => {
  return (
    <div className="modal-body pt-2 pb-2 overflow-visible">
      <form onSubmit={handleSubmit}>
        <div className="bg-dark-card my-3 my-md-4 border-bottom-custome">
          <div className="add-market-card">
            <div className="qt-input mb-3">
              <label htmlFor="inputState" className="form-label">
                Market
              </label>
              <Select
                placeholder="Select Market"
                options={options?.name}
                onChange={handleSelectChange("name")}
                value={options?.name?.find(
                  (option) => option?.label === formData?.name,
                )}
              />
              {error?.name && (
                <div className="invalid-feedback d-block">{error?.name}</div>
              )}
            </div>

            <div className="qt-input  mb-3">
              <label htmlFor="inputState" className="form-label">
                Time Zone
              </label>
              <Select
                placeholder="Select Timezone"
                options={options?.timezone}
                onChange={handleSelectChange("time_zone")}
                value={options?.timezone?.find(
                  (option) => option?.label === formData?.time_zone,
                )}
              />
              {error?.time_zone && (
                <div className="invalid-feedback d-block">
                  {error.time_zone}
                </div>
              )}
            </div>

            <div className="qt-input mb-3">
              <label htmlFor="inputState" className="form-label">
                Currency
              </label>
              <Select
                getOptionLabel={getCurrencyLabel}
                value={currencyData?.find(
                  (currency) => currency?.value === formData?.currency_code,
                )}
                options={currencyData}
                placeholder="Select Currency"
                onChange={handleCurrencySelect}
                menuPlacement="top"
              />
              {error?.currency_code && (
                <div className="invalid-feedback d-block">
                  {error.currency_code}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="btn-bar">
          <button
            type="submit"
            className="btn btn-primary me-auto d-flex"
            data-bs-dismiss={dismissValue}
          >
            <Floppy className="me-2" />
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
