import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../App";
import logoImg from "../../../assets/img/logo.svg";

const Sidebar = () => {
  const { setAdmin, user } = useContext(AppContext);

  return (
    <div className="app-aside">
      <div
        className="offcanvas offcanvas-start"
        data-bs-theme="dark"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <img src={logoImg} alt="logo" />
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <div className="app-aside-top">
            <h4 className="fw-400 aside-title">ON THIS PAGE</h4>
            <ul className="navbar-nav ms-auto fs-5 nav nav-pills flex-column mb-auto">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : ""}`
                  }
                  aria-current="page"
                >
                  <i className="i-dashboard icon-sm"></i>
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/markets"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : ""}`
                  }
                >
                  <i className="i-market icon-sm"></i>
                  Markets
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/assets"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : ""}`
                  }
                >
                  <i className="i-assets icon-sm"></i>
                  Assets
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/strategies"
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : ""}`
                  }
                >
                  <i className="i-strategies icon-sm"></i>
                  Strategies
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="app-aside-bottom">
            <div className="d-flex align-items-center">
              <div className="avatar avatar-lg">AD</div>
              <div className="ms-2">
                <h4 className="fe-400">Admin</h4>
                <p>{user?.email}</p>
              </div>
            </div>
            <i
              role="button"
              className="i-log-out icon-lg"
              onClick={() => setAdmin(null)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
