import { useContext } from "react";
import { CloudUpload } from "react-bootstrap-icons";
import { FileRejection, useDropzone } from "react-dropzone";
import { AppContext } from "../../../App";

export const Dropzone = ({
  setFileNames,
  setFormData,
  fileNames,
  error,
  setIsDirty,
}: {
  setFileNames: any;
  setFormData: any;
  fileNames: string | null;
  error?: boolean;
  setIsDirty?: any;
}) => {
  const { setToastMessage } = useContext(AppContext);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: Blob[], rejectedFiles: FileRejection[]) => {
      setIsDirty(true);
      if (rejectedFiles.length > 0) {
        const firstRejection = rejectedFiles[0];
        if (firstRejection.errors.some((e) => e.code === "file-too-large")) {
          setToastMessage({
            text: "File size exceeds 2MB.",
            type: "error",
          });
        } else if (
          firstRejection.errors.some((e) => e.code === "file-invalid-type")
        ) {
          setToastMessage({
            text: "Only JPG/JPEG and PNG files are allowed.",
            type: "error",
          });
        }
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const base64String = reader.result as string;
        setFormData((prevState: any) => ({
          ...prevState,
          image: base64String,
        }));
        setFileNames(base64String);
      };
      reader.onerror = (error) => {
        console.log("Error converting file to base64: ", error);
      };
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxSize: 2048 * 1024,
  });

  return (
    <div
      {...getRootProps()}
      className={`add-assets-header ${isDragActive ? "dragging" : ""}`}
      style={{
        position: "relative",
        padding: "32px",
        border: `${error ? "1px dashed red" : "1px dashed rgba(199, 215, 248, 0.4)"}`,
        textAlign: "center",
        cursor: "pointer",
      }}
    >
      <input {...getInputProps()} className="hidden-input" />
      <div className="text-center">
        <CloudUpload
          className="icon-xxl"
          style={{
            backgroundColor: "#6002EE",
            borderRadius: 50,
            padding: 10,
          }}
        />
      </div>
      <p className="text-center mb-0">
        Select or drag and drop your chosen image
      </p>
      <p className="text-center mb-0" style={{ color: "grey", marginTop: "0" }}>
        (jpg, png only. Max size 2MB)
      </p>
      {fileNames && (
        <div
          className="image-preview"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          <img
            src={fileNames}
            alt="Preview"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
              maxHeight: "200px",
            }}
          />
        </div>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export const convertImageUrlToBase64 = async (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String);
        };
        reader.onerror = (error) => {
          reject(new Error("Error converting image to base64: " + error));
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => reject(new Error("Error fetching image: " + error)));
  });
};
