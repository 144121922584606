import React from "react";
import logoImg from "../assets/img/logo.svg";

const HowToDelete = () => {
  return (
    <>
      <div className="login-header w-100">
        <img src={logoImg} alt="logo" title="Logo" />
      </div>
      <main className="main how-to-delete p-3">
        <div>
          <h1>How to delete an account?</h1>
          <h3>
            Permanently delete your account, including all data, settings, and
            history.
            <br />
            <br />
            Follow these steps:
          </h3>
          <ul>
            <li>Open the app.</li>
            <li>If not logged in, login to the app using your credentials.</li>
            <li>Navigate to the "Setting" tab.</li>
            <li>Select the option "Delete account" on Account section.</li>
            <li>
              Confirm that you understand your data will be deleted permanently.
            </li>
            <li>Click on "Delete Account" or a similar confirmation button.</li>
            <li>
              Receive confirmation that your account has been successfully
              deleted.
            </li>
          </ul>
        </div>
      </main>
    </>
  );
};
export default React.memo(HowToDelete);
