import React from "react";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";
import { InputField } from "../common/InputField/inputField";
import { FormProps } from "../common/Interface/types";

export const SinglePageForm: React.FC<FormProps> = ({
  formData,
  selectedMarket,
}) => {
  return (
    <form>
      <div className="add-market-content">
        <div className="my-3 my-md-4">
          <div className="add-market-card strategy-section">
            <div className="row g-2">
              <div className="col-lg-6">
                <InputField
                  id="market_name"
                  name="market_name"
                  value={formData.market_name}
                  label="Market"
                  readOnly
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="asset_name"
                  name="asset_name"
                  value={formData.asset_name}
                  label="Asset"
                  readOnly
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="name"
                  name="name"
                  value={formData.name}
                  label="Strategy Name"
                  readOnly
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="password"
                  type="password"
                  name="password"
                  value={"**********"}
                  label="Password"
                  readOnly
                />
              </div>
              <div className="col-lg-6 qt-input">
                <InputField
                  id="recommended_stoploss"
                  name="recommended_stoploss"
                  value={formData.recommended_stoploss}
                  label="Recommended Stoploss (%)"
                  readOnly
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  id="initial_balance"
                  name="initial_balance"
                  value={formData.initial_balance}
                  label={`Initial balance ${selectedMarket ? `(${selectedMarket?.currency_symbol})` : ""}`}
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <div className="row py-3">
                  <div className="qt-input col-6">
                    <label
                      htmlFor="inputEmail4"
                      className="form-label w-auto me-3"
                    >
                      Inverse
                    </label>
                    {formData.inverse ? (
                      <CheckCircleFill className="fs-4 text-success" />
                    ) : (
                      <XCircleFill className="fs-4 text-danger" />
                    )}
                  </div>
                  <div className="qt-input col-6">
                    <label
                      htmlFor="inputEmail4"
                      className="form-label w-auto me-3"
                    >
                      Pyramid
                    </label>
                    {formData.pyramid ? (
                      <CheckCircleFill className="fs-4 text-success" />
                    ) : (
                      <XCircleFill className="fs-4 text-danger" />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="qt-input d-flex align-items-center">
                  <label
                    htmlFor="inputEmail4"
                    className="form-label w-auto me-3"
                  >
                    Risk Profile
                  </label>
                  <i className={`icon-${formData.risk_profile}-risk d-block`} />
                </div>
              </div>

              <div className="col-lg-6 qt-input">
                <InputField
                  id="max_drawdown"
                  name="max_drawdown"
                  value={formData.max_drawdown}
                  label="Max Drawdown (%)"
                  readOnly
                />
              </div>
              <div className="col-lg-6 qt-input">
                <InputField
                  id="win_rate"
                  name="win_rate"
                  value={formData.win_rate}
                  label="Win Rate (%)"
                  readOnly
                />
              </div>
              <div className="col-lg-6 qt-input">
                <InputField
                  id="time_frame"
                  name="time_frame"
                  value={formData.time_frame}
                  label="Time Frame"
                  readOnly
                />
              </div>
              <div className="col-lg-6 qt-input">
                <InputField
                  id="profit_factor"
                  name="profit_factor"
                  value={formData.profit_factor}
                  label="Profit Factor (%)"
                  readOnly
                />
              </div>
            </div>
            {formData.chart_url && (
              <div className="col-12">
                <InputField
                  id="chart_url"
                  name="chart_url"
                  value={formData.chart_url}
                  label="Chart URL"
                  readOnly
                />
              </div>
            )}
            {formData.csv_url && (
              <div className="col-12">
                <InputField
                  id="csv_url"
                  name="csv_url"
                  value={formData.csv_url}
                  label="CSV URL"
                  readOnly
                />
              </div>
            )}
            {formData.video_url && (
              <div className="col-12">
                <InputField
                  id="video_url"
                  name="video_url"
                  value={formData.video_url}
                  label="Video URL"
                  readOnly
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
