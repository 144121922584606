import React from "react";
import { Dropzone } from "../common/Dropzone/dropzone";
import { Dropdown, InputField } from "../common/InputField/inputField";
import { FormProps, MarketData } from "../common/Interface/types";
import { Floppy } from "react-bootstrap-icons";

export const Form: React.FC<FormProps> = ({
  formData,
  selectedMarket,
  marketData,
  errors,
  handleChange,
  handleMarketSelect,
  setFileNames,
  setFormData,
  fileNames,
  handleSubmit,
  setIsDirty,
}) => {
  const getMarketLabel = (market: MarketData) => market.name;
  return (
    <div className="modal-body">
      <form onSubmit={handleSubmit}>
        <div className="add-market-content">
          <div className="my-3 my-md-4">
            <div className="add-market-card">
              <div className="container-fluid">
                <div className="row g-3">
                  <div className="col-12 col-md-6">
                    <Dropzone
                      setFileNames={setFileNames}
                      setFormData={setFormData}
                      fileNames={fileNames}
                      error={errors?.image}
                      setIsDirty={setIsDirty}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Dropdown
                      label="Market"
                      data={marketData}
                      selectedItem={selectedMarket}
                      handleSelect={handleMarketSelect}
                      getItemLabel={getMarketLabel}
                      error={errors?.market_id}
                      placeholder="Select a Market"
                      message="No market available"
                    />
                    <InputField
                      id="assetName"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      label="Asset Name"
                      error={errors?.name}
                    />
                    <InputField
                      id="tickerName"
                      type="text"
                      name="ticker_name"
                      value={formData.ticker_name}
                      onChange={handleChange}
                      label="Ticker Name"
                      error={errors?.ticker_name}
                      maxLength={20}
                    />
                    {selectedMarket?.name?.toLowerCase() === "india" && (
                      <InputField
                        id="symbol_token"
                        name="symbol_token"
                        value={formData.symbol_token}
                        pattern="^[0-9]*$"
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          e.target.value = value.slice(0, 10);
                          handleChange?.(e);
                        }}
                        onChange={handleChange}
                        label="Ticker Token"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-bar">
            <button
              type="submit"
              className="btn btn-primary me-auto d-flex"
              data-bs-dismiss="modal"
            >
              <Floppy className="me-2" />
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
