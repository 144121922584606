import { ChangeEvent, ReactNode } from "react";

export const InputField = ({
  id,
  name,
  value,
  onChange,
  label,
  error,
  radio,
  type,
  checked,
  maxLength,
  icon,
  pattern,
  onInput,
  readOnly,
}: {
  id: string;
  name?: string;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  error?: string;
  type?: string;
  defaultValue?: string;
  radio?: boolean;
  htmlFor?: any;
  placeholder?: string;
  checked?: boolean;
  maxLength?: number;
  icon?: ReactNode;
  pattern?: string;
  onInput?: any;
  readOnly?: boolean;
}) => (
  <div className="qt-input mb-3">
    <label
      htmlFor={radio ? "inlineRadio3" : id}
      className={`${radio ? "form-check-label" : "form-label"}`}
    >
      {label}
    </label>
    <div className={`input-wrapper ${icon ? "d-flex" : "d-inline"}`}>
      <input
        type={type}
        className={`${
          radio
            ? "form-check-input"
            : `form-control text-truncate ${error ? "is-invalid" : ""} `
        }`}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        maxLength={maxLength}
        autoComplete="new-password"
        pattern={pattern}
        onInput={onInput}
        readOnly={readOnly}
      />
      {icon && <span className="icon-container">{icon}</span>}
    </div>
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
);

interface DropdownProps<T> {
  data: T[];
  selectedItem: T | null;
  handleSelect?: (item: T) => void;
  getItemLabel: (item: T) => string;
  error?: string;
  message?: string;
  label: string;
  placeholder?: string;
}

export const Dropdown = <T,>({
  data,
  selectedItem,
  handleSelect,
  getItemLabel,
  error,
  label,
  placeholder,
  message,
}: DropdownProps<T>) => (
  <div className="qt-input st-dropdown mb-3">
    <label htmlFor="inputState" className="form-label">
      {label}
    </label>
    <div className="dropdown w-100">
      <button
        className={`btn btn-secondary dropdown-toggle w-100 text-start text-truncate ${
          error ? "is-invalid" : ""
        }`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        title={selectedItem ? getItemLabel(selectedItem) : placeholder}
      >
        {selectedItem ? getItemLabel(selectedItem) : placeholder}
      </button>
      {error && <div className="invalid-feedback">{error}</div>}
      <ul className="dropdown-menu w-100">
        {data?.length > 0 ? (
          data.map((item: any) => (
            <li key={item?.id}>
              <button
                className="dropdown-item text-truncate"
                type="button"
                onClick={() => handleSelect?.(item)}
                title={getItemLabel(item)}
              >
                {getItemLabel(item)}
              </button>
            </li>
          ))
        ) : (
          <li>
            <span className="dropdown-item disabled">{message}</span>
          </li>
        )}
      </ul>
    </div>
  </div>
);

export const itemsPerPageOptions = [10, 20, 50, 100];
