import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import logoImg from "../assets/img/logo.svg";
import { InputField } from "./common/InputField/inputField";
import {
  validateChangePasswordForm,
  ValidationErrors,
} from "./common/Interface/types";
import { useParams } from "react-router-dom";
import { getData, postData } from "./utils/api";

const ChangePassword = () => {
  const { token: resetPasswordToken } = useParams();
  const [formData, setFormData] = useState({
    confirm_password: "",
    password: "",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("Change Password");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    showErrors && setErrors({});
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateChangePasswordForm(formData);
    if (Object.keys(validationErrors.change_password || {}).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    try {
      await postData(`/resetPassword/${resetPasswordToken}`, null, formData);
      setIsInvalidToken(true);
      setMessage("Your password has been reset successfully.");
    } catch (error) {
      console.log("Error submitting form:", error);
    }
  };

  const checkToken = useCallback(async () => {
    try {
      await getData(`/resetPassword/${resetPasswordToken}`, null);
    } catch (error) {
      setIsInvalidToken(true);
      setMessage("The reset password link has been expired.");
    }
  }, [resetPasswordToken]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const resetPasswordForm = () => {
    return (
      <form className="login-form qt-11-form" onSubmit={handleSubmit}>
        <InputField
          id="password"
          type="password"
          name="password"
          placeholder="******"
          value={formData.password}
          onChange={handleChange}
          label="Password"
          error={errors.change_password?.password}
        />
        <InputField
          id="confirm_password"
          type="password"
          name="confirm_password"
          placeholder="******"
          value={formData.confirm_password}
          onChange={handleChange}
          label="Confirm Password"
          error={errors.change_password?.confirm_password}
        />
        <button type="submit" className="btn btn-primary w-100">
          Submit
        </button>
      </form>
    );
  };

  return (
    <>
      <div className="login-header w-100">
        <img src={logoImg} alt="logo" title="Logo" />
      </div>
      <main className="main login-section after-circle">
        <div className="bg-dark-card d-flex border-0">
          <div className="qt-card w-100">
            <h2>{message}</h2>
            {!isInvalidToken && resetPasswordForm()}
          </div>
        </div>
      </main>
    </>
  );
};
export default React.memo(ChangePassword);
