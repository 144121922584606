import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Header, Sidebar } from "../common";
import { StrategyColumn } from "../common/DataTable/column";
import Table from "../common/DataTable/table";
import { itemsPerPageOptions } from "../common/InputField/inputField";
import {
  AssetData,
  AssetsResponse,
  MarketData,
  MarketsResponse,
  StrategyData,
  validateStrategyForm,
  ValidationErrors,
} from "../common/Interface/types";
import { usePaginationReducer } from "../common/useReducer/usePaginationReducer";
import { deleteData, getData, postData, putData } from "../utils/api";
import { Form } from "./form";
import { DiscardModal, Modal } from "../common/Modal";
import { SinglePageForm } from "./singlePageForm";
import { Clipboard } from "react-bootstrap-icons";

const options = {
  stopLoss: Array.from({ length: 21 }, (_, i) => ({ value: i, label: `${i}` })),
  maxDrawdown: Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  })),
  timeFrame: [
    { value: "3m", label: "3 m" },
    { value: "5m", label: "5 m" },
    { value: "15m", label: "15 m" },
    { value: "30m", label: "30 m" },
    { value: "1h", label: "1 h" },
    { value: "2h", label: "2 h" },
    { value: "4h", label: "4 h" },
    { value: "1d", label: "1 d" },
    { value: "1w", label: "1 w" },
    { value: "custom", label: "Custom" },
  ],
  frame: [
    { value: "s", label: "s" },
    { value: "m", label: "m" },
    { value: "h", label: "h" },
    { value: "d", label: "d" },
    { value: "w", label: "w" },
  ],
};

const StrategyList = () => {
  const { token, setAdmin, setToastMessage } = useContext(AppContext);
  const [marketData, setMarketData] = useState<MarketData[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<MarketData | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<AssetData | null>(null);
  const [assetData, setAssetData] = useState<AssetData[]>([]);
  const [formData, setFormData] = useState<StrategyData>({
    market_name: "",
    asset_name: "",
    name: "",
    initial_balance: 100000,
    risk_profile: "medium",
    password: "Quant11@9090",
    recommended_stoploss: 2,
    max_drawdown: NaN,
    win_rate: undefined,
    profit_factor: undefined,
    time_frame: "15m",
    custome_time: "1",
    frame: "m",
    inverse: true,
    pyramid: false,
    chart_url: "",
    csv_url: "",
    video_url: "",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalView, setIsModalView] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [currentStrategy, setCurrentStrategy] = useState<MarketData | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const {
    state,
    setData,
    setPage,
    setItemsPerPage,
    setSort,
    setTotal,
    setTotalPages,
  } = usePaginationReducer();
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (!localStorage.getItem("authToken")) setAdmin(null);
  }, [setAdmin]);

  const resetForm = () => {
    setFormData({
      market_name: "",
      asset_name: "",
      name: "",
      initial_balance: 100000,
      risk_profile: "medium",
      password: "Quant11@9090",
      recommended_stoploss: 2,
      max_drawdown: NaN,
      win_rate: undefined,
      profit_factor: undefined,
      time_frame: "15m",
      custome_time: "1",
      frame: "m",
      inverse: true,
      pyramid: false,
      chart_url: "",
      csv_url: "",
      video_url: "",
    });
    setSelectedMarket(null);
    setSelectedAsset(null);
    setCurrentStrategy(null);
    setErrors({});
    setShowErrors(false);
    setIsModalVisible(false);
  };

  const fetchStrategy = async () => {
    setIsLoading(true);
    try {
      const query = new URLSearchParams({
        page: state.currentPage.toString(),
        limit: state.itemsPerPage.toString(),
        ...(searchValue && { search: searchValue.trim() }),
        ...(state.sortField && {
          sortColumn: state.sortField,
          sortOrder: state.sortDirection,
        }),
      }).toString();
      const response: any = await getData(`/admin/strategies?${query}`, token);
      const { data: strategies, pageInfo } = response.strategies;
      setData(strategies || []);
      setTotal(pageInfo.total);
      setTotalPages(Math.ceil(pageInfo.total / state.itemsPerPage));
    } catch (error: any) {
      setToastMessage({
        status: error.status,
        text: error?.data || error?.message,
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const handleMarketSelect = (market: MarketData) => {
    setIsDirty(true);
    setSelectedMarket(market);
    const matchingAsset = assetData?.find(
      (asset) => asset?.market_id === market?.id,
    );
    setFormData((prev) => ({
      ...prev,
      market_name: market.name,
      asset_name: matchingAsset ? matchingAsset.name : "",
    }));
    setSelectedAsset(matchingAsset || null);
  };
  const handleAssetSelect = (asset: AssetData) => {
    setIsDirty(true);
    setSelectedAsset(asset);
    setFormData((prev) => ({ ...prev, asset_name: asset.name }));
  };
  const handleRiskProfileSelect = (riskProfile: string) => {
    setIsDirty(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      risk_profile: riskProfile,
    }));
  };
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= state.totalPages) {
      setPage(page);
    }
  };

  const handleItemsPerPageChange = (limit: number) => {
    setItemsPerPage(limit);
    setPage(1);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  useEffect(() => {
    fetchStrategy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentPage,
    state.itemsPerPage,
    searchValue,
    state.sortField,
    state.sortDirection,
  ]);

  const handleClick = async () => {
    setIsModalVisible(true);
    if (token) {
      try {
        const [assetsData, marketsData] = await Promise.all([
          getData<AssetsResponse>("/admin/assets", token),
          getData<MarketsResponse>("/admin/markets", token),
        ]);
        setAssetData(assetsData?.assets?.data);
        setMarketData(marketsData?.markets?.data);
      } catch (error: any) {
        setToastMessage({
          text: error?.data || error?.message,
          type: "error",
        });
      }
    }
  };

  const filteredAssets = assetData?.filter(
    (asset) => asset?.market_id === selectedMarket?.id,
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const validationErrors = validateStrategyForm(formData);
    e.preventDefault();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    setIsDirty(false);
    try {
      if (currentStrategy) {
        await putData(`/admin/strategies/${currentStrategy.id}`, token, {
          chart_url: formData?.chart_url,
          csv_url: formData?.csv_url,
          video_url: formData?.video_url,
        });
        setToastMessage({
          text: "Strategy updated succesfully",
          type: "success",
        });
      } else {
        const submitData: any = { ...formData };
        const timeFrame =
          submitData?.time_frame === "custom"
            ? submitData?.custome_time + "" + submitData?.frame
            : submitData?.time_frame;
        if (submitData.profit_factor || submitData.win_rate || timeFrame) {
          submitData.profit_factor = parseFloat(submitData.profit_factor);
          submitData.win_rate = parseFloat(submitData.win_rate);
          submitData.time_frame = timeFrame;
        }
        await postData("/admin/strategies", token, submitData);

        setToastMessage({
          text: "Strategy added succesfully",
          type: "success",
        });
      }
      resetForm();
      fetchStrategy();
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };
  const handleChange = (e: {
    target: { name: any; value: any; type: any };
  }) => {
    const { name, value } = e.target;
    const newValue =
      name === "inverse" || name === "pyramid" ? value === "true" : value;
    setIsDirty(true);
    setFormData({
      ...formData,
      [name]: newValue,
    });
    showErrors && setErrors({});
  };

  const handleSelectChange = (param: string) => (selectedOption: any) => {
    setIsDirty(true);
    setFormData((prevState) => ({
      ...prevState,
      [param]: selectedOption ? selectedOption.value : null,
    }));
    showErrors && setErrors({});
  };

  const handleClose = () => {
    if (isDirty) {
      setOpen(true);
    } else {
      resetForm();
    }
    setIsDeleteModalVisible(false);
    setIsModalView(false);
  };
  const handleNoClick = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    setOpen(false);
    setIsModalVisible(false);
    setIsDirty(false);
    resetForm();
  };

  const handleSortModelChange = (newModel: any) => {
    const currentSortField = state.sortField;
    const currentSortDirection = state.sortDirection;
    if (currentSortField === newModel) {
      if (currentSortDirection === "desc") {
        setSort(null, null);
      } else {
        setSort(newModel, "desc");
      }
    } else {
      setSort(newModel, "asc");
    }
  };

  const handleDeleteClick = async (id: any) => {
    try {
      await deleteData(`/admin/strategies/${id}`, token);
      setToastMessage({
        text: "Strategy Deleted succesfully",
        type: "success",
      });
      fetchStrategy();
      setIsDeleteModalVisible(false);
    } catch (error: any) {
      setToastMessage({
        text: error?.message,
        type: "error",
      });
    }
  };

  const handleViewClick = (id: any) => {
    const strategyObj = state?.data?.find(
      (data: { id: any }) => data.id === id,
    );
    if (strategyObj) {
      setFormData({
        market_name: strategyObj?.market_name,
        asset_name: strategyObj?.asset_name,
        name: strategyObj?.name,
        initial_balance: strategyObj?.initial_balance,
        risk_profile: strategyObj?.risk_profile,
        password: strategyObj?.password,
        recommended_stoploss: strategyObj?.recommended_stoploss,
        max_drawdown: strategyObj?.max_drawdown,
        win_rate: strategyObj?.win_rate,
        profit_factor: strategyObj?.profit_factor,
        time_frame: strategyObj?.time_frame,
        inverse: strategyObj?.inverse,
        pyramid: strategyObj?.pyramid,
        chart_url: strategyObj?.chart_url,
        csv_url: strategyObj?.csv_url,
        video_url: strategyObj?.video_url,
      });
    }
    setIsModalView(true);
  };
  const copyToClipboard = (id?: any) => {
    const strategyObj = state?.data?.find(
      (data: { id: any }) => data?.id === id,
    );
    const JSONData = {
      strategy_name: strategyObj?.name || formData?.name,
      password: "{{password}}",
      price: "{{strategy.order.price}}",
      action: "{{strategy.order.action}}",
    };
    const jsonData = JSON.stringify(JSONData, null, 2);
    navigator.clipboard.writeText(jsonData).catch((err) => {
      setToastMessage({
        text: err?.data || "Error copying to clipboard..",
        type: "error",
      });
      return;
    });
    setToastMessage({
      text: "Copied to clipboard...",
      type: "success",
    });
  };

  const handleEdit = (id: any) => {
    const strategyObj = state?.data?.find(
      (data: { id: any }) => data.id === id,
    );
    if (strategyObj) {
      setCurrentStrategy(strategyObj);
      setFormData({
        market_name: strategyObj?.market_name,
        asset_name: strategyObj?.asset_name,
        name: strategyObj?.name,
        initial_balance: strategyObj?.initial_balance,
        risk_profile: strategyObj?.risk_profile,
        password: strategyObj?.password,
        recommended_stoploss: strategyObj?.recommended_stoploss,
        max_drawdown: strategyObj?.max_drawdown,
        win_rate: strategyObj?.win_rate,
        profit_factor: strategyObj?.profit_factor,
        time_frame: strategyObj?.time_frame,
        inverse: strategyObj?.inverse,
        pyramid: strategyObj?.pyramid,
        chart_url: strategyObj?.chart_url,
        csv_url: strategyObj?.csv_url,
        video_url: strategyObj?.video_url,
      });
    }
    setIsModalVisible(true);
  };

  return (
    <>
      <Header />
      <main className="main">
        <Sidebar />
        <div className="app-main">
          <Table
            columns={StrategyColumn}
            data={state.data}
            isLoading={isLoading}
            itemsPerPageOptions={itemsPerPageOptions}
            itemsPerPage={state.itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            currentPage={state.currentPage}
            total={state.total}
            onPageChange={handlePageChange}
            name="Strategies"
            addIcon={true}
            editIcon={true}
            deleteIcon={true}
            handleClick={handleClick}
            handleSearch={handleSearch}
            searchValue={searchValue}
            handleSort={handleSortModelChange}
            sortField={state.sortField}
            sortDirection={state.sortDirection}
            handleDeleteClick={handleDeleteClick}
            setDeleteId={setDeleteId}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            handleViewClick={handleViewClick}
            viewStrategy={true}
            copyToClipboard={copyToClipboard}
            handleEditClick={handleEdit}
            title="Add Strategy"
          />
        </div>
      </main>
      {isModalVisible && (
        <Modal
          title={currentStrategy ? "Update Strategy" : "Add Strategy"}
          onClose={handleClose}
        >
          <div className="modal-body">
            <Form
              formData={formData}
              selectedMarket={selectedMarket}
              selectedAsset={selectedAsset}
              marketData={marketData}
              filteredAssets={filteredAssets}
              currentStrategy={currentStrategy}
              errors={errors}
              handleChange={handleChange}
              handleMarketSelect={handleMarketSelect}
              handleAssetSelect={handleAssetSelect}
              options={options}
              handleSelectChange={handleSelectChange}
              handleSubmit={handleSubmit}
              handleRiskProfileSelect={handleRiskProfileSelect}
              setShowErrors={setShowErrors}
              setErrors={setErrors}
            />
          </div>
        </Modal>
      )}
      {isDeleteModalVisible && (
        <Modal
          title="Delete Strategy"
          onClose={handleClose}
          isConfirmation={true}
          handleDeleteClick={() => handleDeleteClick(deleteId)}
          children="Are you sure you want to Delete Strategy?"
        />
      )}
      {open && (
        <DiscardModal
          title="Discard Changes"
          onClose={handleNoClick}
          onYesClose={handleYesClick}
          children="would like to discard changes made?"
        />
      )}
      {isModalView && (
        <Modal title="View Strategy" onClose={handleClose}>
          <div className="modal-body">
            <SinglePageForm
              formData={formData}
              options={options}
              selectedMarket={selectedMarket}
              marketData={marketData}
              errors={errors}
            />
          </div>
          <div className="modal-footer">
            <div className="btn-bar">
              <button
                type="button"
                className="btn btn-primary me-auto d-flex"
                onClick={(id: any) => copyToClipboard?.(id)}
              >
                <Clipboard className="me-2" />
                Copy JSON
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default React.memo(StrategyList);
